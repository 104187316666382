
export enum CONTRACT_STATUS {
    DRAFT = 'DRAFT',
    READY_FOR_APPROVAL = 'READY_FOR_APPROVAL',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    SIGNATURE_PENDING = 'SIGNATURE_PENDING',
    READY_FOR_SIGNATURE = 'READY_FOR_SIGNATURE',
    SIGNATURE_IN_PROGRESS = 'SIGNATURE_IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    SIGNED = 'SIGNED',
    SIGNATURE_COMPLETED = 'SIGNATURE_COMPLETED',
    SIGNATORY_REJECTED = 'SIGNATORY_REJECTED',
}

export enum INTEGRATION_STATUS {
    CONNECTED = 'CONNECTED',
    DISCONNECTED = 'DISCONNECTED',
}


export const enum CONTRACT_SIGNATORY_STATUS {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    WAITING = 'WAITING',
    SIGNED = 'SIGNED',
    NOT_YET_STARTED = 'NOT_YET_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
}