<div class="container">

    @if(approverConf && approvers.length > 0){
    <div class="justify-start  gap-2 w-auto  max-w-fit">
        @for( item of approvers ; track item){
        @if(item.status == APPROVAL_STATUS.APPROVED){
        <div class=" flex items-center space-x-2 px-3 py-1 mb-2 rounded-full cursor-pointer"   [ngStyle]="{
                        'background-color': getBackgroundColorWithOpacity(item.user.colorCode, 0.1, item.status),
                        'border' : '1px solid ' + getBackgroundColorWithOpacity(item.user.colorCode, 20, item.status),
                        'color': getTextColor(item.user.colorCode),
                        'width' : 'fit-content'
                        }">
            <img [src]="item.user | userAvatar" alt="User Avatar" class="w-6 h-6 rounded-full" />
            <span>{{ item.user.fullName ? item.user.fullName : item.user.firstName+' '+item.user.lastName }}</span>
            <div>
                <svg width="12" height="12" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.3335 5.66666L4.00016 8.33332L10.6668 1.66666" stroke="#12B76A" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
        </div>
        }
        @else if (item.status == APPROVAL_STATUS.REJECTED) {
        <div class="flex items-center space-x-2 px-3 py-1 mb-2  rounded-full" [ngStyle]="{
                        'background-color': getBackgroundColorWithOpacity(item.user.colorCode, 0.1, item.status),
                        'border' : '1px solid ' + getBackgroundColorWithOpacity(item.user.colorCode, 20, item.status),
                        'color': getTextColor(item.user.colorCode),
                        'width' : 'fit-content'
                        }">
            <img [src]="item.user | userAvatar" alt="User Avatar" class="w-6 h-6 rounded-full" />
            <span>{{ item.user.fullName ? item.user.fullName : item.user.firstName+'  '+item.user.lastName }}</span>
            <div>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 9L9 1M1 1L9 9" stroke="#D92D20" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>
        </div>
        }
        @else{
        <div class="flex items-center space-x-2 px-3 py-1 mb-2  rounded-full" [ngStyle]="{
                'background-color': getBackgroundColorWithOpacity(item.user.colorCode, 0.1, item.status),
                'color': getTextColor(item.user.colorCode),
                'width' : 'fit-content'
                }">
            <img [src]="item.user | userAvatar" alt="User Avatar" class="w-6 h-6 rounded-full" />
                    <span>{{ item.user.fullName ? item.user.fullName : item.user.firstName+' '+item.user.lastName }}</span>

        </div>
        }

        }
    </div>
    }
    @if(!approverConf && users.length > 0){
    <div class="flex flex-wrap gap-2">
        @for( user of users ; track user){
        <div class="flex items-center space-x-2 px-3 py-1 rounded-full" [ngStyle]="{
          'background-color': getBackgroundColorWithOpacity(user.colorCode, 0.1),
          'color': getTextColor(user.colorCode),
          'width' : 'fit-content'
        }">
            <img [src]="user | userAvatar" alt="User Avatar" class="w-6 h-6 rounded-full" />
            <span>{{ user.fullName ? user.fullName : user.firstName }}</span>
        </div>

        }
    </div>
    }
</div>