import { Component, Input } from '@angular/core';
import { IUser } from '../../../@core/models/model';
import { UserAvatarPipe } from '../../pipes/user-avatar-pipe';
import { CommonModule } from '@angular/common';


export enum CONTRACT_APPROVAL_STATUS {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  WAITING = 'WAITING',
  SKIPPED = 'SKIPPED',
}

export enum CONTRACT_STATUS {
  DRAFT = 'DRAFT',
  READY_FOR_APPROVAL = 'READY_FOR_APPROVAL',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  READY_FOR_SIGNATURE = 'READY_FOR_SIGNATURE',
  SIGNATURE_IN_PROGRESS = 'SIGNATURE_IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  SIGNATURE_COMPLETED = 'SIGNATURE_COMPLETED',
  SIGNATORY_REJECTED = 'SIGNATORY_REJECTED',
}


@Component({
  selector: 'app-user-chips',
  standalone: true,
  imports: [UserAvatarPipe, CommonModule],
  templateUrl: './user-chips.component.html',
  styleUrl: './user-chips.component.scss'
})

export class UserChipsComponent {

  @Input() users: IUser[] = [];

  @Input() approvers !: { user: IUser, status: string }[]

  @Input() approverConf: boolean = false

  APPROVAL_STATUS = CONTRACT_APPROVAL_STATUS
  constructor() { }

  trackByUser(index: number, user: any): string {
    return user.id; // Adjust this if you have a different unique identifier
  }

  getBackgroundColorWithOpacity(colorCode: string, opacity: number, status?: string): string {

    if (status === this.APPROVAL_STATUS.APPROVED) {
      // Green
      return `rgba(0, 128, 0, ${opacity})`;
    }
    if (status === this.APPROVAL_STATUS.REJECTED) {
      // Red
      return `rgba(255, 0, 0, ${opacity})`;
    }
    const hex = colorCode.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  getTextColor(bgColor: string): string {
    const hex = bgColor.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return 'black'
  }



}
