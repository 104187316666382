import { Injectable } from "@angular/core";
import { BaseService } from "../../@core/services/base.service";
import { HttpClient } from "@angular/common/http";
import { IContract, IContractSignatoryConf, SIGNATORY_TYPE } from "../models/contract-management.model";
import { environment } from "../../../environments/environment.dev";

@Injectable({ providedIn: 'root' })
export class ContractSignatoryService extends BaseService {
    constructor(
        protected override http: HttpClient
    ) {
        super(http);
    }

    apiUrl = environment.apiUrl + '/signatory/'

    setDefaultSignatory(signatory: SIGNATORY_TYPE) {
        return this.post(this.apiUrl + 'set-default-signatory', {
            signatory: signatory
        })
    }

    getDefaultSignatory() {
        return this.get<{
            defaultSignatory: SIGNATORY_TYPE,
        }>(this.apiUrl + 'get-default-signatory')
    }

    updateSignatories(contractId: string, signatories: { email: string, name: string }[]) {
        return this.post<IContract>(this.apiUrl + 'update-signatories/' + contractId, { signatories })
    }

    fetchContractSignatories(contractId: string) {
        return this.get<IContractSignatoryConf>(this.apiUrl + 'fetch-contract-signatories/' + contractId)
    }

    downloadDocument(attachmentId: string) {
        return this.get<{ data: Uint8Array }>(this.apiUrl + 'download-signed-document', { attachmentId })
    }

    startSignatoryFlow(contractId: string, signatoryType: SIGNATORY_TYPE) {
        return this.post<IContract>(this.apiUrl + 'start-signatory-flow', {
            contractId,
            signatoryType
        })
    }

    resetSignatoryFlow(contractSignatoryConfId: string) {
        return this.post<IContract>(this.apiUrl + 'reset-signatory-flow/' + contractSignatoryConfId)
    }
}
