import { IUser } from "../@core/models/model";

export const NO_OF_EMPLOYEES = ['1-10', '11-50', '51-100', '101-500', '501-1000', '1000+'];
export const WORKSPACE_PREFIX = 'inncircles.ai/';

export function retrieveDisplayNameForUser(user: IUser) {
    if (!user) {
        return '';
    }
    return user.firstName ? (user.lastName ? user.firstName + ' ' + user.lastName : user.firstName) : (user.lastName ? user.lastName : (user.email ? user.email : ''));
}

export function downloadPdfDocument(uint8Array: Uint8Array, filename: string) {
    const blob: Blob = new Blob([uint8Array], {
        type: 'application/pdf'
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${filename}.pdf`;
    document.body.append(link);
    link.click();
    link.remove();
}

export function toFixedNumber(num: number, decimals: number): number {
    return Number(num.toFixed(decimals))
}

export function getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}