import { PRODUCT_WALKTHROUGH_TYPE } from "../../contract-management/components/contract-list/contract-list.component";
import { IContract } from "../../contract-management/models/contract-management.model";
import { IChecklistItem } from "../../contract-management/models/settings.model";
import { CONTRACT_CHECKLIST_STATUS, USER_ROLE, USER_TYPE } from "./enums";

export interface ResponseJson<T> {
    data: T;
    success: boolean;
    message: string;
}


export interface Document {
    _id: string
}

export interface IOrganization extends Document {
    name: string;
    companyId: string;
    office_address: string;
    size: string;
    email: string;
    company_contact_no: {
        countryCode: string,
        contactNumber: string,
        countryShortName: string
    };
    createdBy: string;
    workspaceSlug: string;
};

export interface IAttachments extends Document {
    name: string,
    attachment: IAttachment['_id']
}


export interface IAttachment extends Document {
    filename: string;
    url: string;
    type: string;
    createdAt?: Date;
    updatedAt?: Date;
    isPublic: boolean;
    fullUrl?: string;
    relatedTo?: string;
    project?: string,
    superRef?: string | IAttachment
    filePath?: string
}


export enum USER_TYPES {
    INNCIRCLES = 'INNCIRCLES',
    VENDEE_ADMIN = 'VENDEE_ADMIN',
    OWNER = 'OWNER',
    ADMIN = 'ADMIN'
}

export enum USER_AVAILABILITY_STATUS {
    AVAILABLE = 'AVAILABLE',
    BUSY = 'BUSY',
    DO_NOT_DISTURB = 'DO_NOT_DISTURB',
    BE_RIGHT_BACK = 'BE_RIGHT_BACK',
    APPEAR_OFFLINE = 'APPEAR_OFFLINE',
    OUT_OF_OFFICE = 'OUT_OF_OFFICE'
}

export enum SIGNATURE_TYPES {
    ATTACHMENT = 'ATTACHMENT',
    INITIALS = 'INITIALS',
    CANVAS = 'CANVAS',
}

export interface IUser extends Document {
    role: USER_ROLE;
    organization: IOrganization | string
    username: string;
    password: string;
    title: string;
    linkedRoster?: string;
    firstName: string;
    lastName: string;
    gender: GENDER;
    employeeId: string;
    phoneNumber: {
        countryCode: string,
        contactNumber: string,
        countryShortName: string
    };
    email: string;
    type: USER_TYPES;
    availabilityStatus: USER_AVAILABILITY_STATUS;
    profilePicture: IAttachment;
    profile: string;
    active: boolean;
    id: string;
    permissions?: string[];
    signature: {
        signType: SIGNATURE_TYPES;
        attachment: IAttachment['_id'];
        initials: string;
        canvas: string;
    };
    token?: string;
    project?: string;
    builder: string;
    // assets?: string[] | IAttachment[];
    profileUrl?: string;
    outlookMailId?: string;
    outlookGuid?: string;
    fullName?: string;
    vendorNumber?: string,
    colorCode: string;
    assets?: { attachment: IAttachment, name: string }[];
    anonymous?: boolean;
    productWalkThrough?: {
        type: PRODUCT_WALKTHROUGH_TYPE,
        status: boolean,
    }[]
}

export enum GENDER {
    'MALE' = 'MALE',
    'FEMALE' = 'FEMALE',
}

export enum CONTRACT_USER_ROLE {
    OWNER = 'OWNER',
    EDITOR = 'Editor',
    VIEWER = 'Viewer',
    COMMENTER = 'Commenter'
}

export interface IInvitation extends Document {
    _id: string,
    email: string,
    role: USER_ROLE,
    organization: string,
    invitedBy: string,
    createdAt: Date,
    updatedAt: Date,
}

export enum APPROVER_TYPE {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL'
}

export interface IShareUser {
    _id?: string,
    user?: string | IUser,
    email: string,
    name: string,
    status?: string,
    role?: SHARE_ACCESS,
    type: USER_TYPE,
    modifiedAt?: Date,
}

export enum SHARE_ACCESS {
    VIEW = 'View',
    EDIT = 'Edit',
    COMMENT = 'Comment',
    RESTRICTED = 'Restricted',
    OWNER = 'Owner'
}

export interface IShareContract extends Document {
    organization: string,
    contract: string | IContract,
    users: IShareUser[],
    anyone: SHARE_ACCESS,
    createdAt: Date,
    updatedAt: Date,
}
export interface IContractChecklistItem extends Document {
    organization: string,
    contract: string | IContract,
    checklistItem: string | IChecklistItem,
    status: CONTRACT_CHECKLIST_STATUS
}

export interface IAdobeSignSetting extends Document {
    clientId: string
    clientSecret: string
    organization: string | IOrganization,
    consent: boolean
}