import { Component, ElementRef, EventEmitter, HostListener, Injector, Input, OnInit, Output, signal } from '@angular/core';
import { ICountryCodes, countryCodes } from './helpers/countries-helper';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl, ValidationErrors } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { NgClass, NgStyle } from '@angular/common';
import { STRING_UPPER_LOWER_SPECIAL_CHARACTERS_REGEX } from './helpers/regex-helper';

export interface PhoneNumberValue {
  countryCode: string;
  contactNumber: string;
  countryShortName: string;
}

@Component({
  selector: 'arn-phone-input',
  standalone: true,
  imports: [
    NgClass,
    NgStyle
  ],
  templateUrl: './phone-input.component.html',
  styleUrl: './phone-input.component.scss',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: PhoneInputComponent,
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: PhoneInputComponent,
    multi: true
  }]
})

export class PhoneInputComponent implements ControlValueAccessor, OnInit {
  @Input() readOnly: boolean = false;
  @Output() phoneNumberChange = new EventEmitter<PhoneNumberValue>();
  dropDownOptions = signal<ICountryCodes[]>(countryCodes);
  searchDropDownOptions = signal<ICountryCodes[]>(countryCodes);
  searchText = signal<string>('');
  phoneNumberUtil = PhoneNumberUtil.getInstance();
  isFocused = signal<boolean>(false);
  formControl: NgControl | null = null;
  isDropDownOpen = signal<boolean>(false);
  selectedPhoneNumber = signal<PhoneNumberValue>({ countryCode: "91", contactNumber: '', countryShortName: 'IN' });
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  onChange = (value: PhoneNumberValue) => { }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = () => { }

  constructor(
    private ref: ElementRef,
    private injector: Injector
  ) { }

  ngOnInit(): void {
    try {
      this.formControl = this.injector.get(NgControl);
    } catch { /* empty */ }
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.isDropDownOpen.set(false);
    }
  }

  toggleDropDown() {
    this.isDropDownOpen.set(!this.isDropDownOpen());
    this.onTouch();
  }

  setCountryCode(option: ICountryCodes) {
    this.selectedPhoneNumber.update((phoneNumber) => { return { ...phoneNumber, countryCode: option.phoneCode.toString().replace('+', ''), countryShortName: option.code } })
    this.updateFormControl();
    this.toggleDropDown();
  }

  setPhoneNumber(event: Event) {
    const numberInput = (event.target as HTMLInputElement);
    numberInput.value = numberInput.value.replace(STRING_UPPER_LOWER_SPECIAL_CHARACTERS_REGEX, '');
    this.selectedPhoneNumber.update((current) => { return { ...current, contactNumber: numberInput.value } })
    this.updateFormControl();
  }

  getCountryCode(phoneNumber: string) {
    const parsedNumber = this.phoneNumberUtil.parse(`+${phoneNumber}`);
    const countryCode = `${parsedNumber.getCountryCode()}`;
    return { countryCode, contactNumber: parsedNumber?.getNationalNumber()?.toString() ?? '' }
  }

  searchCountry(event: Event) {
    this.searchText.set((event.target as HTMLInputElement).value);
    this.searchDropDownOptions.set(this.dropDownOptions().filter((countryCode: ICountryCodes) => {
      return countryCode.name.toLowerCase().includes(this.searchText().toLowerCase()) || countryCode.phoneCode.toLowerCase().includes(this.searchText().toLowerCase()) || countryCode.code.toLowerCase().includes(this.searchText().toLowerCase())
    }))
  }

  parsePhoneNumber(fullNumber: string): PhoneNumberValue {
    try {
      const parsedNumber = this.phoneNumberUtil.parse(`+${fullNumber}`);
      const countryCode = `${parsedNumber.getCountryCode()}`;
      const countryShortName = this.phoneNumberUtil.getRegionCodeForNumber(parsedNumber);

      return {
        countryCode: countryCode,
        contactNumber: parsedNumber?.getNationalNumber()?.toString() ?? '',
        countryShortName: countryShortName ?? ''
      };
    } catch {
      return { countryCode: "91", contactNumber: '', countryShortName: '' };
    }
  }

  writeValue(value: PhoneNumberValue | string | null): void {
    if (!value) {
      this.selectedPhoneNumber.set({ countryCode: "91", contactNumber: '', countryShortName: 'IN' });
      return;
    }
    if (typeof value === 'string') {
      this.selectedPhoneNumber.set(this.parsePhoneNumber(value));
    } else {
      this.selectedPhoneNumber.set(value);
    }
  }

  registerOnChange(fn: (value: PhoneNumberValue) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;  
    if (!value || value.countryCode.trim() === '' || value.contactNumber === '') {
      return { required: true };
    }
    try {
      const phoneNumberString = `+${value.countryCode.trim()}${value.contactNumber.trim()}`;
      const parsedNumber = this.phoneNumberUtil.parseAndKeepRawInput(phoneNumberString, value.countryShortName);
      const isNumberValid = this.phoneNumberUtil.isValidNumber(parsedNumber);
      if (!isNumberValid) {
        return { invalidNumber: true };
      }
      return null;
    } catch (err) {
      return { invalidNumber: true };  
    }
  }
  

  updateFormControl() {
    const phoneNumberValue = this.selectedPhoneNumber();
    this.onChange(phoneNumberValue);
    this.onTouch();
    this.phoneNumberChange.emit(this.selectedPhoneNumber());
  }
}